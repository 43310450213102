/* Default styling for the navbar */
.navbar-custom {
  background-color: rgb(30, 31, 34);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2em 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10.5em;
}

.navbar-name-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-name {
  font-family: 'Roboto', sans-serif;
  font-weight: bolder;
  font-size: 40px;
  color: white;
  margin-top: 40px;
  margin-bottom: -30px;
}

.navbar-subtitle {
  font-family: monospace;
  font-size: 14px;
  margin-left: 1em;
  align-items: center;
}

.navbar-subtitle span:nth-child(1) {
  color: rgb(139, 88, 138); /* Pink */
}

.navbar-subtitle span:nth-child(2) {
  color: rgb(197, 145, 114); /* Orange */
}

.navbar-subtitle span:nth-child(3) {
  color: rgb(107, 166, 239); /* Blue */
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0em;
  margin-top: 2.7em;
}

.navbar-logo img {
  max-height: 4.8rem;
}

.navbar-nav {
  margin-top: 6.3rem;
  align-items: center;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-brand {
  flex: 1;
  text-align: left;
}

.navbar-nav {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.navbar-toggle {
  border: none;
  align-self: center;
}

.navbar-nav .nav-link {
  font-family: monospace;
  font-size: 14px;
  margin-left: 15px;
  color: rgb(117, 163, 116); /* Green color for navbar options */
}

.navbar-nav .nav-link:hover {
  color: rgb(197, 175, 122); /* Yellow color on hover */
}

.navbar-nav .nav-dropdown .dropdown-toggle,
.navbar-nav .nav-dropdown .dropdown-toggle:focus {
  font-family: monospace;
  font-size: 14px;
  margin-left: 15px;
  color: rgb(117, 163, 116); /* Green color for the "Projects" dropdown */
}

.navbar-nav .nav-dropdown .dropdown-toggle:hover {
  color: rgb(197, 175, 122); /* Yellow color on hover */
}

.navbar-nav .nav-dropdown .dropdown-menu {
  background-color: rgb(43, 45, 48); /* Set the background color of the dropdown menu */
}

.navbar-nav .nav-dropdown .dropdown-menu .dropdown-item {
  font-family: monospace;
  font-size: 14px;
  color: rgb(117, 163, 116); /* Green color for dropdown items */
}

.navbar-nav .nav-dropdown .dropdown-menu .dropdown-item:hover {
  color: rgb(197, 175, 122); /* Yellow color on hover */
  background-color: rgba(255, 255, 255, 0.1); /* Add a subtle background color on hover */
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .navbar-custom {
    padding: 1em 0;
  }

  .navbar-name {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .navbar-subtitle {
    font-size: 12px;
  }

  .navbar-content {
    padding: 0 1em;
  }

  .navbar-nav {
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
  }

  .navbar-nav .nav-link,
  .navbar-nav .nav-dropdown .dropdown-toggle {
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin-left: 0;
  }

  .navbar-nav .nav-dropdown .dropdown-menu {
    width: 100%;
  }

  .navbar-nav .nav-dropdown .dropdown-menu .dropdown-item {
    font-size: 12px;
    right: 0;
    left: auto;
  }

  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-dropdown .dropdown-toggle:hover,
  .navbar-nav .nav-dropdown .dropdown-menu .dropdown-item:hover {
    color: rgb(197, 175, 122); /* Yellow color on hover */
  }

  /* Custom styles for the navbar toggle button */
  .navbar-toggler {
    border: none; /* Remove the border */
    padding: 0;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 30px;
    height: 24px;
    position: relative;
    background-color: transparent; /* Ensure no background color */
  }

  .navbar-toggler-icon::before,
  .navbar-toggler-icon::after,
  .navbar-toggler-icon div,
  .navbar-toggler-icon .middle1,
  .navbar-toggler-icon .middle2,
  .navbar-toggler-icon .middle3 {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(117, 163, 116); /* Green color for lines */
    position: absolute;
    transition: all 0.3s;
  }

  .navbar-toggler-icon::before {
    top: 0;
  }

  .navbar-toggler-icon::after {
    bottom: 0;
  }

  .navbar-toggler-icon .middle1 {
    top: 6px;
  }

  .navbar-toggler-icon .middle2 {
    top: 12px;
  }

  .navbar-toggler-icon .middle3 {
    top: 18px;
  }

  .navbar-toggler:hover .navbar-toggler-icon::before,
  .navbar-toggler:hover .navbar-toggler-icon::after,
  .navbar-toggler:hover .navbar-toggler-icon .middle1,
  .navbar-toggler:hover .navbar-toggler-icon .middle2,
  .navbar-toggler:hover .navbar-toggler-icon .middle3,
  .navbar-toggler:focus .navbar-toggler-icon::before,
  .navbar-toggler:focus .navbar-toggler-icon::after,
  .navbar-toggler:focus .navbar-toggler-icon .middle1,
  .navbar-toggler:focus .navbar-toggler-icon .middle2,
  .navbar-toggler:focus .navbar-toggler-icon .middle3,
  .navbar-toggler:active .navbar-toggler-icon::before,
  .navbar-toggler:active .navbar-toggler-icon::after,
  .navbar-toggler:active .navbar-toggler-icon .middle1,
  .navbar-toggler:active .navbar-toggler-icon .middle2,
  .navbar-toggler:active .navbar-toggler-icon .middle3 {
    background-color: rgb(197, 175, 122); /* Yellow color on hover/click */
  }

  /* Position dropdown menu to the left */
  .navbar-nav .nav-dropdown .dropdown-menu {
    right: auto;
    left: 0;
  }

  /* Ensure the menu icon does not move */
  .navbar-toggler.collapsed .navbar-toggler-icon div {
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar-toggler.collapsed .navbar-toggler-icon .middle1,
  .navbar-toggler.collapsed .navbar-toggler-icon .middle2,
  .navbar-toggler.collapsed .navbar-toggler-icon .middle3 {
    top: 50%;
    transform: translateY(-50%);
  }
}