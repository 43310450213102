/* AboutContact.css */

.about-contact-container {
    max-width: 70em;
    margin: 0 auto;
    padding: 3em;
    color: white;
}

.about-contact-container h1 {
    font-size: 3rem;
    margin-bottom: 0.5em;
    text-align: left;
}

.about-contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3em;
}

.profile-section,
.contact-section {
    text-align: left;
}

.contact-section h2 {
    margin-bottom: 1em;
    text-align: center;
    font-family: monospace;
    color: rgb(117, 163, 116);
}

.contact-section form {
    display: grid;
    grid-gap: 1em;
    font-family: monospace;
    color: rgb(117, 163, 116);
}

.contact-section .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.contact-section input,
.contact-section textarea {
    width: 100%;
    padding: 0.5em;
    background-color: rgb(43, 45, 48);
    color: white;
    border: none;
}

.contact-section textarea {
    resize: vertical;
    min-height: 150px;
}

.contact-section button {
    padding: 0.6em 1.2em;
    background-color: rgb(43, 45, 48);
    color: rgb(117, 163, 116);
    border: none;
    cursor: pointer;
    font-family: monospace;
    font-size: 1em;
    transition: color 0.3s;
    width: 120px;
    justify-self: center;
}

.contact-section button:hover {
    color: rgb(197, 175, 122);
}

.contact-info {
    margin-top: 2em;
}

.profile-picture {
    display: block; /* Adjust this based on your layout preference */
    max-width: 15rem; /* Adjust the size as needed */
    margin: 0 auto 2em; /* Center the image and add some margin below */
    border-radius: 50%; /* Keeps the image circular, if it's not already */
}

