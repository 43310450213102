/* Project Grid Container */
.project-grid-header {
  text-align: center;
  margin-bottom: 4em;
}

.project-grid-container {
  max-width: 70em;
  margin: 0 auto;
  padding: 0 3em;
}

/* Project Grid */
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
  gap: 4em;
  margin-top: -2em;
}

.project-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s;
  aspect-ratio: 16 / 9;  /* Set a fixed aspect ratio for all project items */
}

.project-item:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.project-item:hover .project-overlay {
  opacity: 1;
}

.project-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.project-description {
  font-size: 16px;
  text-align: center;
  padding: 0 20px;
  color: white;
}

/* Technology Icons Style Update */
.technology-icons {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 5px 0;
}

.technology-icons img {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  vertical-align: middle;
}