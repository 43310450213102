/* App.css */

.main-content {
  margin-top: 10rem;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: rgb(30, 31, 34) !important;
  color: white;
}

.container {
  padding: 2rem 0;
  background: rgb(30, 31, 34);
  margin-bottom: 2rem;
}

.App {
  text-align: center;
  color: white;
}

.profile-section {
  text-align: left;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image-modal-content {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(30, 31, 34);
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.image-modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.image-modal-controls {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.prev-btn,
.next-btn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.prev-btn:hover,
.next-btn:hover {
  color: #007bff;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.close-btn:hover {
  color: #007bff;
}
