/* ProjectDetail.css */

.project-detail {
  margin-top: 5em;
  color: white;
}

.project-detail-container {
  max-width: 70em;
  margin: 0 auto;
  padding: 0 3em;
}

.project-detail h1 {
  font-size: 3rem;
  padding-top: 1em;
  margin-bottom: 1em;
  color: white;
  text-align: center;
}

.project-detail-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  padding-bottom: 2em;
}

.project-detail-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s;
}

.project-detail-image:hover {
  transform: scale(1.05);
}

.project-detail-section {
  margin-bottom: 30px;
  color: white;
  text-align: left;
}

.project-detail-section p {
  margin-bottom: 10px;
  color: white;
}

.project-detail-subtitle {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-detail-section:last-child {
  text-align: center;
}

.git-repository-section a {
  color: rgb(117, 163, 116);
  text-decoration: none;
  transition: color 0.3s;
}

.git-repository-section a:hover {
  color: rgb(197, 175, 122);
}

